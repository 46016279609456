import React, { useEffect, useState } from "react";
import { FaInstagram, FaWhatsapp, FaFacebook, FaLinkedin, FaEnvelope, FaPhone } from "react-icons/fa";
import { Link } from "react-router-dom";
import UniHomeLogo from "./Assets/images/UniHome.png";
import axios from "axios";

const Footer = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchContacts() {
      try {
        const response = await axios.get("https://admin.unih0me.com/public/api/contacts");
        setContacts(response.data.data.contacts);
        setLoading(false);
      } catch (error) {
        setError("Error fetching contacts");
        setLoading(false);
      }
    }

    fetchContacts();
  }, []);

  const renderIcon = (icon) => {
    switch (icon) {
      case "fab fa-facebook-f":
        return <FaFacebook className="text-[#1877F2]" />;
      case "fab fa-instagram":
        return <FaInstagram className="text-[#E4405F]" />;
      case "fab fa-whatsapp":
        return <FaWhatsapp className="text-[#25D366]" />;
      case "fab fa-linkedin":
        return <FaLinkedin className="text-[#0077B5]" />;
      case "fas fa-mail-bulk":
        return <FaEnvelope className="text-[#D44638]" />;
      case "fas fa-phone":
        return <FaPhone className="text-blue-500" />;
      default:
        return null;
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <footer className="bg-gray-800 text-white" dir="ltr">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Logo and Description */}
          <div className="text-center lg:text-left">
            <Link to="/" className="flex items-center justify-center lg:justify-start mb-4">
              <img src={UniHomeLogo} alt="UniHome Logo" className="w-20 h-20 rounded-xl mr-3" />
            </Link>
            <p className="text-sm md:text-base leading-6">
              Get the best private lessons online with professional teachers. Choose from a variety of live courses and learn from home with ease.
            </p>
          </div>

          {/* Links Section */}
          <div className="text-center lg:text-left">
            <h6 className="text-[#ff5a1f] text-lg font-bold uppercase mb-4">Platform</h6>
            <ul className="space-y-3">
              <li>
                <Link to="/Privacy-Policy" className="hover:underline">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/Developers" className="hover:underline">
                  Developers
                </Link>
              </li>
              <li>
                <Link to="/How-To-Use" className="hover:underline">
                  How To Use
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Us Section */}
          <div className="text-center lg:text-left">
            <h6 className="text-[#ff5a1f] text-lg font-bold uppercase mb-4">Contact Us</h6>
            <div className="flex justify-center lg:justify-start items-center">
              <a
                href={`tel:${contacts[4]?.contact}`}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#ff5a1f] text-white py-4 px-4 rounded-lg hover:bg-[#e04e1a] transition-colors duration-300 flex justify-center items-center gap-2"
              >
                <span>Call US</span>
                <span><FaPhone /></span>
              </a>
            </div>
          </div>

          {/* Social Links Section */}
          <div className="text-center lg:text-left">
            <h6 className="text-[#ff5a1f] text-lg font-bold uppercase mb-4">Links</h6>
            <div className="flex justify-center lg:justify-start gap-4 flex-wrap">
              {contacts?.map((contact) => (
                <a
                  key={contact.id}
                  href={
                    contact.type === "phone"
                      ? `tel:${contact.contact}`
                      : contact.type === "whatsapp"
                        ? `https://wa.me/${contact.contact.replace(/[^0-9]/g, "")}`
                        : contact.type === "email"
                          ? `mailto:${contact.contact}`
                          : contact.contact
                  }
                  target={contact.type === "social" || contact.type === "whatsapp" ? "_blank" : "_self"}
                  rel="noopener noreferrer"
                  className="text-xl hover:opacity-75 transition-opacity duration-300"
                >
                  {renderIcon(contact.icon)}
                </a>
              ))}
            </div>

            <div className="two-info mt-4">
              <p className="leading-6 py-2 px-3 ring-[0.7px] ring-blue-500 rounded-3xl w-fit hover:bg-blue-500/10 flex items-center">
                <FaEnvelope className="mr-2 text-[#D44638]" />
                {contacts[3]?.contact}
              </p>
              <p className="leading-6 py-2 px-3 ring-[0.7px] ring-blue-500 rounded-3xl w-fit hover:bg-blue-500/10 flex items-center">
                <FaPhone className="mr-2 text-blue-500" />
                {contacts[4]?.contact}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-900 text-center py-4">
        <p className="text-sm font-bold">2024 © UniHome. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
