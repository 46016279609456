import axios from 'axios';
import React, { Suspense, useEffect, useState } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import countries from "../flag.json";
import "./TeacherComp.css";
import Test2 from './Test2';

export default function TeacherComp() {
    const { t } = useTranslation();
    const [dataApi, setDataApi] = useState([]);
    const [workHours, setWorkHours] = useState({});
    const [loading, setLoading] = useState(true);

    const getCountryFlag = (countryName) => {
        const country = countries.find((c) => c.country === countryName);
        return country ? country.flag : "";
    };

    const apiData = async () => {
        try {
            const res = await axios.get("https://admin.unih0me.com/public/api/teachers");
            setDataApi(res.data.data.teachers);
        } catch (error) {
            console.error("Error fetching teachers:", error);
        }
    };

    const fetchWorkHours = async (teacherId) => {
        try {
            const res = await axios.get(`https://admin.unih0me.com/public/api/session/teacher/${teacherId}`);
            return res.data.data.sessiontables.filter(session => session.status === 1);
        } catch (error) {
            console.error(`Error fetching work hours for teacher ${teacherId}:`, error);
            return [];
        }
    };

    useEffect(() => {
        const loadData = async () => {
            await apiData();
            setLoading(false);
        };
        loadData();
    }, []);

    useEffect(() => {
        if (dataApi.length > 0) {
            dataApi.forEach(async (teacher) => {
                const sessions = await fetchWorkHours(teacher.id);
                setWorkHours((prev) => ({ ...prev, [teacher.id]: sessions }));
            });
        }
    }, [dataApi]);

    // Function to format time as 12-hour AM/PM
    const formatTime = (time) => {
        const [hour] = time.split(':');
        let formattedHour = parseInt(hour, 10);
        const ampm = formattedHour >= 12 ? 'PM' : 'AM';
        formattedHour = formattedHour % 12 || 12;
        return `${formattedHour} ${ampm}`;
    };

    // Get today's date in YYYY-MM-DD format
    const getTodayDate = () => {
        return new Date().toISOString().split('T')[0];
    };

    return (
        <div className="py-6 px-1 md:px-11" dir='ltr'>
            {loading ? (
                <div className="w-3/4 flex justify-center items-center mx-auto my-10 gap-10">
                    <Suspense fallback={<div>Loading...</div>}>
                        <Test2 />
                    </Suspense>
                </div>
            ) : (
                <div className="space-y-8">
                    {dataApi.map((teacher) => {
                        const teacherSessions = workHours[teacher.id] || [];

                        // Filter sessions for today
                        const todaySessions = teacherSessions.filter(session => session.date === getTodayDate());
                        const rushHours = todaySessions.map(session => formatTime(session.time));

                        return (
                            <Link to={`/TeacherCards/${teacher.id}`} key={teacher.id} className="teacher-link">
                                <Suspense fallback={<div>Loading Card...</div>}>
                                    <div className="teacher-card-container">
                                        <div className="teacher-avatar">
                                            <div className="teacher-avatar-img-wrapper">
                                                <img
                                                    className="teacher-avatar-img"
                                                    src={teacher.image || "https://site.unih0me.com/unihome/public/default.jpg"}
                                                    alt="Teacher"
                                                />
                                            </div>
                                        </div>

                                        <div className="teacher-info">
                                            <h2 className="teacher-name">
                                                Hi, I'm {teacher.firstname} {teacher.lastname}
                                            </h2>
                                            <p className="teacher-intro">
                                                {teacher.intro || "Learn with a professional English teacher!"}
                                            </p>

                                            <div className="teacher-stats">
                                                <div className="teacher-stat-item">
                                                    <span className="teacher-stat-label">{t('Country')}</span>
                                                    <strong className="teacher-country">
                                                        {t(teacher.country)}
                                                        <span>
                                                            <img
                                                                className="country-flag"
                                                                src={getCountryFlag(teacher.country)}
                                                                alt="flag"
                                                            />
                                                        </span>
                                                    </strong>
                                                </div>
                                                <div className="teacher-stat-item">
                                                    <span className="teacher-stat-label">{t('Lessons')}</span>
                                                    <strong className="teacher-stat-value">
                                                        {teacher.lessons || "0"}
                                                    </strong>
                                                </div>
                                                <div className="teacher-stat-item">
                                                    <span className="teacher-stat-label">{t('Students')}</span>
                                                    <strong className="teacher-stat-value">{teacher.students || "0"}</strong>
                                                </div>
                                            </div>

                                            {/* Rush Hour Section */}
                                            {/* <div className="teacher-rush-hour">
                                                <span className="teacher-stat-label">{t('Rush Hours')}</span>
                                                <div className="rush-hour-container">
                                                    {rushHours.length > 0 ? rushHours.map((hour, index) => (
                                                        <span key={index} className="rush-hour-circle">{hour}</span>
                                                    )) : <span>No session available today</span>}
                                                </div>
                                            </div> */}

                                            <div className="teacher-rating">
                                                <AiFillStar className="star-icon" />
                                                <span className="teacher-review">
                                                    ({teacher.review == null ? "0" : teacher.review} {t('Reviews')})
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Suspense>
                            </Link>
                        );
                    })}
                </div>
            )}
        </div>
    );
}
