import React from 'react';

const HowToUse = () => {
    const videos = [
        {
            id: 1,
            title: 'شرح كيفية دخول موقع يونيهوم',
            url: 'https://www.youtube.com/embed/EOl7XvqE9rg',
        },
        {
            id: 2,
            title: 'شرح كيفية اختيار المدرس المناسب مع مواعيدك ومعرفة الفيديو والجدول الخاص بالمدرس',
            url: 'https://www.youtube.com/embed/oXtrsFTK8GQ',
        },
        {
            id: 3,
            title: 'شرح الDashboard وشرح الدروس وكيفية حضور الQuiz',
            url: 'https://www.youtube.com/embed/DaqiuD1J7Lk',
        },
        {
            id: 4,
            title: 'شرح تفاصيلي لمتابعة المحاضرات وجميع عمليات وكيفية التواصل مع الدعم الفني لاكاديمية يونيهوم',
            url: 'https://www.youtube.com/embed/CEt5XrbAOUI',
        },
        {
            id: 5,
            title: 'شرح تفاصيلي لكيفية حجز المحاضرة وايضا كيفية إلغاءها مع التوضيح ان يجب الغاء اي محاضره قبلها ب 24 ساعة',
            url: 'https://www.youtube.com/embed/aFDIWSkQH5g',
        },
    ];

    return (
        <div className="min-h-screen flex flex-col py-12 px-6 sm:px-12 lg:px-20 bg-gray-50" dir='rtl'>
            <header className="mb-10">
                <h1 className="text-center text-6xl font-bold text-white my-6 relative">
                    <span className="bg-gradient-to-r from-orange-500 to-blue-500 text-transparent bg-clip-text">How To Use</span>
                    <span className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-orange-500 to-blue-500"></span>
                </h1>
            </header>

            <main className="flex-grow grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                {videos.map((video) => (
                    <div
                        key={video.id}
                        className="shadow-lg rounded-3xl overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-2xl bg-white"
                    >
                        <h2 className="text-lg h-24 font-semibold bg-gradient-to-r from-gray-200 to-gray-300 text-gray-800 p-5 border-b border-gray-200">
                            {video.id}{"-"}{" "}{video.title}
                        </h2>
                        <div className="h-64">
                            <iframe
                                src={video.url}
                                title={video.title}
                                className="w-full h-full"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                ))}
            </main>
        </div>
    );
};

export default HowToUse;
